import gameConfig from '@content/gameconfig';
import { initializeApp } from 'firebase/app';
import {
  serverTimestamp,
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
} from 'firebase/firestore/lite';
import { v4 as uuidv4 } from 'uuid';
import encrypt from './caeserCipher';
import { setLocalStoreUserId, getLocalStoreUserId } from './localStore';

let cachedSessionObject: SessionData | null = null;

// by default both strategies are enabled unless specified by env
let loggingStrategy = {
  firestore: process.env.GATSBY_USE_FIRESTORE !== 'false',
  pixelTracker: process.env.GATSBY_USE_PIXEL !== 'false',
};
const rootCollection = 'sessions';

// TODO: This alternative logger will live alongside our standard firestore one
// this one uses pixel tracking
const pixelTracker = async ({
  collectionName,
  data,
}: {
  collectionName:
    | 'sessions'
    | 'answers'
    | 'corrections'
    | 'demographics'
    | 'events';
  data: Omit<LogData, 'collection_name'> | Omit<SessionData, 'collection_name'>;
}) => {
  if (loggingStrategy.pixelTracker === false) {
    return;
  }
  const parameterizedData = Object.entries(data)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
    )
    .join('&');
  // the first ep is getting the raw end point for dev, and the alternative comes from netlify when in prod
  // on netlify it gets proxied to the actual endpoint (see netlify.toml)
  try {
    await fetch(
      `https://apple-core.netlify.app/pixel?cn=${collectionName}&${encrypt(parameterizedData)}`,
      {headers: {mode: "no-cors"}}
    );
  } catch (e) {
    // failure means that pixelTracker is no longer viable
    loggingStrategy.pixelTracker = false;
    throw new Error(e);
  }
};

// stub here if we choose not to use firestore
let db = null;

if (loggingStrategy.firestore) {
  initializeApp({
    apiKey: process.env.GATSBY_FIRESTORE_API_KEY,
    projectId: process.env.GATSBY_FIRESTORE_PROJECT,
  });
  db = getFirestore();
}

interface SessionData {
  // this id is only relevant with pixels, as it is part of the payload itself
  session_id?: string;
  user_id: string;
  created: Date;
  host: string;
  language: string;
  survey_name: string;
  version: string;
  core_version: string;
  stream_name: string;
  referrer: string;
}

export type OptionalSessionData = Partial<SessionData>;

/** initialize a unique entry into our firebase store */
export const initSession = async (
  optSessionData: OptionalSessionData = {},
  // this is when we are using the pixel tracker and want to perform an update
  optSessionId: string | null = null,
): Promise<string | null> => {
  // we need to first make a unique player id here if not already one in local store
  const userId = (() => {
    const localUserId = getLocalStoreUserId();
    if (localUserId) {
      return localUserId;
    }
    const newUserId = uuidv4();
    // we must this user id in local for retrieval in case user resets
    setLocalStoreUserId(newUserId);
    return newUserId;
  })();

  const streamTableNames = gameConfig.Stream_Table_Names;
  const refObj: SessionData = cachedSessionObject
    ? { ...cachedSessionObject, ...optSessionData }
    : {
        user_id: userId,
        created: serverTimestamp(),
        host: window?.location?.host,
        language:
          gameConfig.Languages_Used?.[0]?.Content_Type ||
          gameConfig.Languages_Used?.Content_Type,
        survey_name: gameConfig.Version,
        // basically the hash
        version: window.location.hash.replace('#', ''),
        // to identify which code release we are on
        core_version: `${gameConfig.coreVersion}${
          process.env.BRANCH ? `-${process.env.BRANCH}` : ''
        }`,
        referrer: document?.referrer || '',
        // this is subject to change as game continues (provided we are using profiler)
        stream_name: Array.isArray(streamTableNames)
          ? streamTableNames[0]
          : streamTableNames,
        ...optSessionData,
      };
  cachedSessionObject = refObj;
  let sessionId = optSessionId;

  // if we already have a sessionId passed, we cannot initiate using firestore, as this will create a new session
  if (!sessionId) {
    if (loggingStrategy.firestore) {
      try {
        const docRef = await addDoc(collection(db, rootCollection), refObj);
        // this id is used as our unique session id
        // if the user refreshing, the id is gone and user is kicked back
        sessionId = docRef?.id;
      } catch (e) {
        console.error('firestore failed ----------------', e);
        // fallback to a uuid so that our pixel can work
        sessionId = uuidv4();
        // raise failed flag, as this means firestore cannot reliably work anymore
        loggingStrategy.firestore = false;
      }
    } else {
      sessionId = uuidv4();
    }
  }

  try {
    // this might fail if CORS error or otherwise
    await pixelTracker({
      collectionName: 'sessions',
      data: {
        ...refObj,
        session_id: sessionId,
      },
    });
  } catch (e) {
    console.error('pixel tracker failed ----------------', e);
  }

  // if both our strategies fail/are not enabled, we cannot reliably log
  if (
    loggingStrategy.firestore === false &&
    loggingStrategy.pixelTracker === false
  ) {
    console.error('logging strategies failed ----------------');
    throw new Error(
      'logging strategies failed, cannot reliably log, please contact support',
    );
  }

  console.info(
    '%c 🔎 For transparency, you can see all the data that we log below 🔎 ',
    'color: red',
  );
  console.info('LOG: ', refObj);

  return sessionId;
};

/** each entry in our db has these in common */
export interface CommonLogData {
  // this is optional, and is the name of the content type entry on airtable
  question_name?: string;
  // question_index: number;
  step_counter?: number;
}

export interface Answers {
  /* text is weird, we can say, this is more like the value (TODO: change name later) */
  answer_text: string;
  duration_in_seconds: number;
  // effectively the name that is supplied on airtable, it contains the type in there as well
  question_type: string;
  /** its boolean because our claim answers are mainly booleans */
  // chosen_answer_value: boolean;
  // the last option here is for when you are using this interface in claims, claims have no results
  result: 'correct' | 'incorrect' | '';
  collection_name: 'answers';
}

interface Correction {
  comments: string;
  source_url: string;
  collection_name: 'corrections';
}

interface Demographic {
  /** this can be an array as well because demo questions can have multiple options */
  answers: string[] | object;
  collection_name: 'demographics';
}

interface Event {
  event_type:
    | 'click'
    | 'open_context_menu'
    | 'toggle_lang'
    | 'set_stream'
    | 'toggle_settings'
    | 'session_kicked'
    | 'rank_up'
    | 'rank_down'
    | 'consent'
    | 'reset_game'
    | 'finished_game'
    | 'error'
    | 'session_init_in_ms'
    | 'init_performance'
    | 'navigate';
  location?: string;
  /** a little more specific than just the question name, can be descriptive such as "help button" etc. */
  target: string | number;
  collection_name: 'events';
}

export type LogData = Answers | Correction | Demographic | Event;

export const log = async (
  data: LogData & CommonLogData,
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  /** NOTE: why is this in prod? This represents transparency. Anyone can know what we intake.
   * This transparency is similar to seeing your debug log on a variety of apps and services
   */
  console.info('LOG: ', data);
  const { collection_name, ...dataMinusCollectionName } = data;

  if (loggingStrategy.firestore) {
    const docRef = collection(
      db,
      rootCollection,
      sessionId || 'error',
      data.collection_name,
    );
    await addDoc(docRef, { ...dataMinusCollectionName });
  }

  await pixelTracker({
    collectionName: collection_name,
    data: {
      ...dataMinusCollectionName,
      session_id: sessionId || 'error',
    },
  });
};

export const batchLog = async (
  data: LogData[] & CommonLogData[],
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  // // Get a new write batch TODO: batching does not seem to work...for now
  // I asked SO https://stackoverflow.com/questions/70160877/how-to-batch-adddoc-in-firestore
  // const batch = writeBatch(db);

  // // for each data, set the batch
  // data.forEach(datum => {
  //   const docRef = collection(
  //     db,
  //     rootCollection,
  //     sessionId || 'error',
  //     datum.collection_name,
  //   );
  //   batch.set(docRef, doc);
  // });

  // await batch.commit();

  let promiseBatch: Promise<any>[] = [];
  data.map(datum => {
    const { collection_name, ...datumMinusCollectionName } = datum;
    console.info('LOG: ', datumMinusCollectionName);
    if (loggingStrategy.firestore) {
      const docRef = collection(
        db,
        rootCollection,
        sessionId || 'error',
        datum.collection_name,
      );
      promiseBatch = [
        ...promiseBatch,
        addDoc(docRef, { ...datumMinusCollectionName }),
      ];
    }
    promiseBatch = [
      ...promiseBatch,
      pixelTracker({
        collectionName: collection_name,
        data: {
          ...datumMinusCollectionName,
          session_id: sessionId || 'error',
        },
      }),
    ];
  });

  await Promise.all(promiseBatch);
};

export const setLanguage = async (
  language: string,
  sessionId: string | null,
) => {
  if (loggingStrategy.firestore) {
    const docRef = doc(db, rootCollection, sessionId);
    await updateDoc(docRef, { language });
  }
  // we cannot "update" our documents per-se, so we just create a new session obj
  if (loggingStrategy.pixelTracker) {
    await initSession(
      {
        ...cachedSessionObject,
        language,
      },
      sessionId,
    );
  }
};

export const setStreamName = async (
  streamName: string,
  sessionId: string | null,
) => {
  if (loggingStrategy.firestore) {
    const docRef = doc(db, rootCollection, sessionId);
    await updateDoc(docRef, { stream_name: streamName });
  }
  if (loggingStrategy.pixelTracker) {
    await initSession(
      {
        ...cachedSessionObject,
        stream_name: streamName,
      },
      sessionId,
    );
  }
};
