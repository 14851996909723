/** this slice pertains to the sending of data to our firestore */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as loggingClient from '@utils/loggingClient';
import { addNewNotification } from '@slices/gameStateSlice';
import { RootState } from '../store';

interface LoggingState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  sessionId: string | null;
  /** every request increments this count */
  logStepCounter: number;
}

export const initialLoggingState: LoggingState = {
  loading: 'idle',
  sessionId: null,
  logStepCounter: 0,
};

export const startNewSession = createAsyncThunk(
  'logging/startNewSession',
  async (
    data: loggingClient.OptionalSessionData,
    { dispatch, rejectWithValue },
  ) => {
    const timeoutId = setTimeout(() => {
      dispatch(
        addNewNotification(
          'Things are taking longer than usual...please try again',
        ),
      );
      rejectWithValue('session timeout error');
    }, 10000); // wait 10 sec
    try {
      const response = await loggingClient.initSession(data);
      return response;
    } catch (e) {
      dispatch(addNewNotification('Woah something is wrong!'));
      return rejectWithValue('session server error');
    } finally {
      clearTimeout(timeoutId);
    }
  },
);

export const logItem = createAsyncThunk<
  // Return type of the payload creator
  void,
  loggingClient.LogData & loggingClient.CommonLogData,
  {
    state: RootState;
  }
>('logging/logItem', async (logData, { getState }) => {
  const state = getState();
  await loggingClient.log(
    {
      ...logData,
      step_counter: state.logging.logStepCounter,
    },
    state.logging.sessionId,
  );
});

export const batchLogItem = createAsyncThunk<
  // Return type of the payload creator
  void,
  // here particularly, we want the question name as you may be batching multiple items of different names
  (loggingClient.LogData & { question_name: string })[],
  {
    state: RootState;
  }
>('logging/batchLogItem', async (logData, { getState }) => {
  const state = getState();
  // go through each batch and add the correct step counter
  // we use the same step for each
  const countedLogData = logData.map(e => ({
    ...e,
    step_counter: state.logging.logStepCounter,
  }));
  await loggingClient.batchLog(countedLogData, state.logging.sessionId);
});

const incrementCounter = (state: LoggingState) => {
  state.logStepCounter += 1;
};

export const loggingSlice = createSlice({
  name: 'logging',
  initialState: initialLoggingState,
  reducers: {
    // used when we find an id on the users ls and need to set it (shorting the session init step)
    forceSetSessionId: (state, action) => {
      const id = action.payload;
      state.sessionId = id;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(startNewSession.fulfilled, (state, action) => {
        // this is stored only during the games runtime
        state.sessionId = action.payload;
      })
      .addCase(logItem.fulfilled, incrementCounter)
      .addCase(batchLogItem.fulfilled, incrementCounter)
      .addCase('gameState/resetGame', () => ({
        ...initialLoggingState,
      }));
  },
});

export const { forceSetSessionId } = loggingSlice.actions;

export default loggingSlice.reducer;
